import { Typography } from "@material-ui/core/";
import { graphql } from 'gatsby';
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState, useContext } from 'react';
import AccentFrame from "../../components/accentFrame";
import Layout from "../../components/layout";
import Li from "../../components/li";
import QuestionCard from "../../components/questionCard";
import { QuestionnaireContext } from "../../context/questionnaireContext";

function Message(props) {
  const{selector} = props
  const {t} = useTranslation();

  return(
    <AccentFrame>
    {selector === "consult" 
        ? 
          <div>
            <Typography>
              <strong>
                {t("Not comfortable with your decision?")}
              </strong>
              <br/>
              <br/>
              {t("We advise you to")}
            </Typography>
            <ul>
              <Li>
                {t("Take another look at the information presented in the")} 
                <Link to="/trisomies">{t("Trisomie")}</Link>, <Link to="/screening">{t("Screening")}</Link>, 
                <Link to="/diagnostic">{t("Diagnostic")}</Link>.
              </Li>
              <Li>
                <Trans i18nKey="seeTheResources">
                  See the <Link to="/more-information">en savoir plus</Link> page and the <Link to="/faq">Frequently Asked Questions (FAQ)</Link>
                </Trans>
              </Li>
              <Li>
                {t("Discuss with your partner and the health professionals who support you.")} 
              </Li>
            </ul>
          </div>
        : 
          <strong>
            {t("You are comfortable with your decision!")}
          </strong>
        }
    </AccentFrame>
  )
}

export default function Part5() {
  const [answers, setAnswers] = useState({});
  const [messageSelector, setMessageSelector]= useState(null);
  const {t} = useTranslation();

  const handleChange = (question) => (event) => {
    const temp = {...answers}
    temp[question] = event.target.value
    setAnswers(temp)
  }

  useEffect(() =>{
    if(Object.keys(answers).length === questions.length) {
      return hasOneNegative() ? setMessageSelector("consult") : setMessageSelector("thanks");
    }
  }, [answers]);

  const hasOneNegative = () => {
    let oneNegative = false
    Object.entries(answers).forEach(([_,v]) => {
      if(v === "no"){
        oneNegative = true
      }
    })
    return oneNegative;
  }
  const questions = [
    t("Do you feel sure about the best choice for you?"),
    t("Do you know the benefits and risks of each option?"),
    t("Are you clear about which benefits and risks matter most to you?"),
    t("Do you have enough support and advice to make a choice?")
  ]

  const choices = [{value: "yes", label:t("Yes")}, {value: "no", label:t("No")}]

  return ( 
    <Layout pageTitle={t("Are you comfortable with your decision?" )} next="/results">
      {questions.map((question, i)=> (
        <QuestionCard key={i} question={question} choices={choices} answer={answers[`q${i}`]} handleChange={handleChange(`q${i}`)}/>
      ))}
      <Typography variant="body1" gutterBottom={true}>
        <strong>© SURE test : O’Connor et Légaré, 2008. </strong>
      </Typography>
      {messageSelector ?  <Message selector={messageSelector}></Message> : null}
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
